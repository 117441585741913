/*
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-05-16 15:56:32
 */
import agentApplyPage from "@/views/agentApply"
export default [{
    name: 'agentApply',
    path: '/agentApply',
    meta: {
        id: '0',
        icon: 'User',
        title: 'agent_audit_management',
        name: 'agent_audit_management',
        cache: true,
		permission:'agent-manage'
    },
    component: agentApplyPage
}, ]