<template>
  <el-header>
    <el-row>
      <el-col :span="1" class="open-menu">
        <el-icon @click="menuOpenOrClose">
          <expand v-if="isCollapse" />
          <fold v-if="!isCollapse" />
        </el-icon>
      </el-col>
      <el-col :span="15">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(bc,index) in breadcrumb" :to="index === 0 ? { path: bc.path } : ''" :key="bc.path"> {{ $t(bc.meta.name) }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8" style="text-align:right;">
        <el-dropdown style="margin-right:30px;line-height:52px;" v-if="switchLanguage">
          <span class="el-dropdown-link">
            <!-- {{ $t("language") }} -->
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changeLang('zh-cn')">简体中文</el-dropdown-item>
              <el-dropdown-item @click="changeLang('en')">English</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="line-height:52px;">
          <span class="el-dropdown-link">
            <el-icon>
              <avatar />
            </el-icon>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="openChangePassword">{{$t("change_password")}}</el-dropdown-item>
              <el-dropdown-item @click="logout">{{$t("login_out")}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
import { computed } from 'vue'
import config from '@/config'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
// import { setLocale } from '@/utils/localforage'
import { getTagTitleName } from '@/utils/helper'
import { Fold, Expand, Avatar, ArrowDown } from "@element-plus/icons-vue"

export default {
  name: 'MojitoHeader',
  components: {
    Fold, Expand, Avatar, ArrowDown
  },
  props: {
    isCollapse: Boolean
  },
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()

    const openChangePassword = () => {
      router.push({name: 'changePasswordPage'})
    }

    const logout = () => {
      store.dispatch("logoutHandle").then(router.push({
        name: config.loginRouteName
      }))
    }

    // const i18n = useI18n()

    const changeLang = (lang) => {
      // store.commit("SET_LOCALE", lang)
      // i18n.locale.value = lang
      // setLocale(lang)
    }
    console.log(store.getters.breadcrumb);
    return {
      openChangePassword,
      logout,
      changeLang,
      getTagTitleName,
      showAuthorGitHubUrl: config.showAuthorGitHubUrl,
      switchLanguage: config.switchLanguage,
      breadcrumb: computed(() => store.getters.breadcrumb),
      menuOpenOrClose: () => {
        emit('menu', !props.isCollapse)
      }
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-header {
    border-bottom: 1px solid #e6e6e6;
    height: 52px;
    line-height:52px;
  }
  .el-button {
    border:none;
  }

  .open-menu {
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .el-breadcrumb {
    line-height:52px;
  }
  a {
    text-decoration:none;
  }
</style>