import http from '@/utils/http'
import { downloadFile } from '@/utils/helper'

export const getUserlist = (params) => {
    return http.get('user/list', { params })
};
export const setUserLevel = (params) => {
    return http.post('user/set_level', params)
};
export const setUserBalance = (params) => {
    return http.post('user/set_balacne', { params })
};
