/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:39:27
 * @LastEditTime: 2022-03-19 09:51:52
 */
import { createI18n } from 'vue-i18n'
import en from './en'
import zhCn from './zh-cn'
import config from '@/config'

const messages = {
  'en': {
    ...en,
  },
  'zh-cn': {
    ...zhCn,
  }
}
export default createI18n({
  locale: localStorage.getItem('lang') || config.locale,
  fallbackLocale: 'en',
  globalInjection:true,
  messages
})
