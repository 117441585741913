/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:25:35
 * @LastEditTime: 2022-03-19 09:45:12
 */
import LoginPage from "@/views/auth/login"

export default [
  {
    name: 'login',
    path: '/login',
    component: LoginPage
  },
]
