/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 14:28:31
 * @LastEditTime: 2022-05-16 17:22:12
 */
/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 08:45:59
 * @LastEditTime: 2022-03-19 10:34:40
 */
import layout from '@/layout/index.vue'
import PageNotFound from "@/components/ErrorPage/PageNotFound.vue"

import dashboard from './modules/dashboard'

import userlist from './modules/userlist'

import agentApply from './modules/agentApply'

import withdraw from './modules/withdraw'

import auth from './modules/auth'
import ChangePasswordPage from "@/views/auth/changePassword.vue"

// import menu from './modules/menu'
// import permission from './modules/permission'
const routes = [{
        name: 'main',
        path: '/',
        meta: {
            title: 'home',
            name: 'home',
            permission: 'home'
        },
        redirect: {
            name: 'dashboard',
        },
        component: layout,
        children: [
            ...dashboard,
			...userlist,
            ...agentApply,
			...withdraw,
            {
                name: 'changePasswordPage',
                path: '/change-password',
                meta: {
                    title: "changePassword",
                    name: 'change_pwd',
                    show:false
                },
                component: ChangePasswordPage,
            },
        ]
    },
    ...auth,
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
        name: 'notFound'
    }

]

export default routes