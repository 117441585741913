/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 08:45:59
 * @LastEditTime: 2022-05-07 14:43:58
 */
import {
    createApp
} from 'vue'
import i18n from '@/lang/index'
import router from './router'
import store from './store'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as Icons from '@element-plus/icons-vue'
import '@/assets/css/app.css';
import 'element-plus/dist/index.css'
import '@/assets/css/layout.scss'
const app = createApp(App)

app.use(store)
    .use(router)
    .use(i18n)

for (const iconName in Icons) {
    if (Reflect.has(Icons, iconName)) {
        app.component(iconName, Icons[iconName])
    }
}
app.use(ElementPlus).mount('#app')