/*
 * @Descripttion: 
 * @Date: 2022-04-22 16:11:04
 * @LastEditTime: 2022-04-26 16:45:01
 */
import http from '@/utils/http'
import { downloadFile } from '@/utils/helper'

export const getAgentApplyList = (params) => {
    return http.get('agent_apply/list', { params })
};

export const geteAgentApplyDetail = (params) => {
    return http.get('agent_apply/detail', { params })
};

export const passAgentApply = (params) => {
    return http.post('agent_apply/pass', params)
};

export const paidAgentApply = (params) => {
    return http.post('agent_apply/paid', params)
};

export const refuseAgentApply = (params) => {
    return http.post('agent_apply/refuse', params)
};