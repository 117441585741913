/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-03-22 16:55:57
 */
import dashboardPage from "@/views/dashboard"
export default [
    {
      name: 'dashboard',
      path: '/dashboard',
      meta: {
        id:'0',
        icon:'House',
        title: 'dashboard',
        name:'home',
        cache: true,
        notClosable: true,
        permission: 'home'
      },
      component: dashboardPage
    },
  ]
  