/*
 * @Descripttion: 
 * @Date: 2022-04-22 16:11:04
 * @LastEditTime: 2022-04-26 16:45:01
 */
import http from '@/utils/http'
import { downloadFile } from '@/utils/helper'

export const getWithdrawList = (params) => {
    return http.get('withdraw/list', { params })
};

export const passWithdraw = (params) => {
    return http.post('withdraw/pass', { params })
};

export const paidWithdraw = (params) => {
    return http.post('withdraw/paidPass', params)
};

export const refuseWithdraw = (params) => {
    return http.post('withdraw/refuse', params)
};