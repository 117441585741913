/*
 * @Descripttion: 这是描述
 * @Date: 2022-04-13 09:18:12
 * @LastEditTime: 2022-04-22 17:44:09
 */
import { ElNotification } from 'element-plus'
import i18n from '@/lang'
const { t } = i18n.global

export default {
    //三种成功
    addSuccess: (message) => {
        ElNotification.success({
            title: t("add"),
            message: message ? message : t("createSuccess"),
            showClose: false,
        })
    },
    editSuccess: (message) => {
        ElNotification.success({
            title: t("edit"),
            message: message ? message : t("editSuccess"),
            showClose: false,
        })
    },
    deleteSuccess: (message) => {
        ElNotification.success({
            title: t("delete"),
            message: message ? message : t("deleteSuccess"),
            showClose: false,
        })
    },
    //三种失败
    createFailed: (message) => {
        ElNotification.error({
            title: t("add"),
            message: message ? message : t("createFailed"),
            showClose: false,
        })
    },
    editFailed: (message) => {
        ElNotification.error({
            title: t("edit"),
            message: message ? message : t("editFailed"),
            showClose: false,
        })
    },
    deleteFailed: (message) => {
        ElNotification.error({
            title: t("delete"),
            message: message ? message : t("deleteFailed"),
            showClose: false,
        })
    },
    //公用
    success: (message,tips="提示") => {
        ElNotification.success({
            title: tips,
            message: message ? message : t("createSuccess"),
            showClose: false,
        })
    },
    failed: (message,tips="提示") => {
        ElNotification.error({
            title: tips,
            message: message ? message : t("deleteFailed"),
            showClose: false,
        })
    },
}