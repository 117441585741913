export default {
    "home":"首页",
	"change_password":"修改密码",
	"login_out":"退出登录",
	"welcome":"欢迎使用后台管理系统",
	"agent_audit_management":"代理商审核管理",
	"refresh":"刷新",
	"filter":"筛选",
	"agent_id":"代理商ID",
	"apply_for_an_account":"申请账号",
	"apply_for_agency_level":"申请代理级别",
	"application_for_agency_area":"申请代理区域",
	"withdraw_time":"提现时间",
	"status":"状态",
	"action":"操作",
	"view":"查看",
	"approved":"审核通过",
	"confirm_payment":"确认支付",
	"refuse":"拒绝",
	"user_information":"用户信息",
	"application_type":"申请类型",
	"time_period_selection":"时间段选择",
	"to":"到",
	'start_time':"开始时间",
	"end_time":"结束时间",
	"cancel":"取消",
	"submit":"提交",
	"detail":"详情",
	"territory":"代理区域",
	"company_name":"公司名称",
	"company_size":"公司规模",
	"office_address":"办公地址",
	"contacts_name":"联系人",
	"contact_information":"联系方式",
	"service_phone":"服务电话",
	"application_description":"申请说明",
	"agency_fees":"代理费用",
	"application_date":"申请时间",
	"confirm":"确定",
	"change_pwd":"修改密码",
	"change_pwd_tip":"修改成功后会自动退出当前登录！",
	"old_pwd":"旧密码",
	"new_pwd":"新密码",
	"confirm_password":"确认密码",
	"reset":"重置",
	"please_input_your_old_password":"请输入旧密码",
	"please_enter_a_new_password":"请输入新密码",
	"the_password_must_be_between_6_and_32_characters":"密码必须为 6 ~ 32 位字符",
	"please_enter_the_new_password_again":"请再次输入新密码",
	"entered_passwords_differ":"两次输入的密码不一致",
	"login":"登陆",
	"please_enter_the_login_account":"请输入登陆账号",
	"please_enter_the_password":"请输入密码",
	"user_name_is_required":"名字必填",
	"password_is_required":"密码必填",
	"user_manage":"用户管理",
	"account":"账号",
	"grade":"等级",
	"franchise_amount":"加盟金额",
	"edit":"编辑",
	"submission_time":"提交时间",
	"success":"操作成功",
	"original_grade":"原等级",
	"payment_for_goods":"货款",
	"positive_numbers_increase_negative_numbers_subtract":"正数为增加，负数为减去",
	"live_streaming_permissions":"直播权限",
	"disable":"启用",
	"enable":"禁用",
	"withdrawal_management":"提现管理",
	"amount":"金额",
	"withdrawal_method":"提现方式",
	"name":"姓名",
	"bank":"开户行",
	"reject_reason":"拒绝原因",
	"payment":"打款",
	"confirm_approval":"确定审核通过？",
	"prompt":"提示",
	"confirm_paid":"确定已支付？",
	"please_fill_in_the_reason_for_rejection":"请填写拒绝理由",
	"refusal_to_withdraw":"拒绝提现"
}