/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 08:45:59
 * @LastEditTime: 2022-05-16 17:22:06
 */
import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import config from '@/config'
import routes from './routes.js';
import store from '@/store'
import { getToken, getPermissions } from '@/utils/localforage'
import {
  ElMessage
} from 'element-plus'
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  console.log('beforeEach....');
  if (config.loginRouteName === to.name) {
    next()
  } else {
    // next()
    let login = new Promise((resolve, reject) => {
      getToken().then(token => {
        if (!token || !token.hasOwnProperty('token')) {
          console.log('getToken1');
          reject({
            name: config.loginRouteName
          })
        } else {
          console.log('getToken2');

          if (!store.getters.token) {
            store.commit('SET_TOKEN', {
              token
            })
          }
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })

    let permission = new Promise((resolve, reject) => {
      if (!to.meta.permission || to.meta.permission == 'home') {
        console.log('permission1');
        resolve()
      } else {
        console.log('permission2');

        getPermissions().then(permissions => {
          if (permissions.indexOf(to.meta.permission) < 0) {
            reject(`You do not have permission to access ${to.meta.permission}`)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      }
    })

    Promise.all([login, permission]).then(result => {
      console.log('to.name',to);

      next()
    }).catch(error => {
      let varType = typeof error;
      if (varType === 'object') {
        next({
          name: error.name
        })
      } else {
        ElMessage({
          message: error,
          type: 'error'
        })
        console.log('from.name',from.name);
        next({
          name: from.name
        })
      }
    })
  }
})

export default router