/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:39:27
 * @LastEditTime: 2022-05-11 10:41:18
 */
import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'
import config from '@/config'

// const httpRequest = axios.create({
//   timeout: 10000,
//   baseURL: import.meta.env.VITE_APP_API_URL
// })
const httpRequest = axios.create({
  timeout: 120000,
  baseURL: (window._env && window._env.API_ROOT ? window._env.API_ROOT : process.env.VUE_APP_BASE_API)
})
httpRequest.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export function setHttpToken(token) {
  httpRequest.defaults.headers.common.Authorization = `Bearer ${token}`
}

function handleResponse(response) {
  if(!response){
    return Promise.reject('网络出错或超时')
  }
  
  let message =  response.hasOwnProperty('data') && response.data.hasOwnProperty('message') && response.data.message ?response.data.message:response.statusText
  //let message =  response?.data?.hasOwnProperty('message') && response.data.message ?response.data.message:response.statusText
  console.log({message})

  if (!message && Object.prototype.hasOwnProperty.call(response.data, 'errors') && response.data.errors instanceof Object) {
  //if ( response?.data?.hasOwnProperty('errors') && response.data.errors instanceof Object) {
    message = '';
    for (let key in response.data.errors) {
      let items = response.data.errors[key]
      if (typeof items === 'string') {
        message += `${items} \n`
      } else {
        response.data.errors[key].forEach(item => {
          message += `${item} \n`
        })
      }
      break
    }
  }
  
  if (response.status === 10006 || (response.status === 200 && response.data.code === 10006)) {
    // 未登陆
    ElMessage.warning({
      message: response.data.message,
      duration: 1500,
    })
    router.push({ name: config.loginRouteName })
  } else if (response.status != 200 || (response.status == 200 && response.data.code !== 0)) {
    // 出错了
    ElMessage.warning({
      message,
      duration: 1500,
    })

    if(response.status == 200 && (response.data.code == 501 || response.data.code == 601)){
      return Promise.reject(response.data)
    }else{
      return Promise.reject(message)
    }
    
  } else {
    // 成功
    //console.log('response.data',response.data)
    return Promise.resolve(response.data)
  }
}

httpRequest.interceptors.response.use(
  response => {
    // return response
    return handleResponse(response)
  },
  error => {
    return handleResponse(error.response)
    /*
    let message = error.response.data.message ? error.response.data.message : error.response.statusText

    if (error.response.status === 422 && Object.prototype.hasOwnProperty.call(error.response.data, 'errors')) {
      message = '';
      for (let key in error.response.data.errors) {
        let items = error.response.data.errors[key]
        if (typeof items === 'string') {
          message += `${items} \n`
        } else {
          error.response.data.errors[key].forEach(item => {
            message += `${item} \n`
          })
        }
        break
      }
    }
    if (error.response.status === 401) {
      ElMessage.warning({
        message: error.response.data.message,
        duration: 1500,
      })
      router.push({ name: config.loginRouteName })
    } else {
      ElMessage.warning({
        message,
        duration: 1500,
      })

      return Promise.reject(error)
    }
    */
  }
)

export default httpRequest
