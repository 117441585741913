<template>
	<el-dialog :modelValue="dialogVisible" width="620px" :title="$t('detail')">
		<el-form >
			<el-form-item :label='$t("territory")' :label-width="formLabelWidth" prop="grade">
				{{form.grade}}-{{form.province}}{{form.city}}{{form.district}}
			</el-form-item>
			<el-form-item prop="company_name" :label='$t("company_name")' :label-width="formLabelWidth">
				{{form.company_name}}
			</el-form-item>
			<el-form-item prop="company_size" :label='$t("company_size")' :label-width="formLabelWidth">
				{{form.company_size}}
			</el-form-item>
			<el-form-item prop="work_address" :label='$t("office_address")' :label-width="formLabelWidth">
				{{form.work_province}}{{form.work_city}}{{form.work_district}}{{form.work_address}}
			</el-form-item>
			<el-form-item prop="contact_person" :label='$t("contacts_name")' :label-width="formLabelWidth">
				{{form.contact_person}}
			</el-form-item>
			<el-form-item prop="contact_type" :label='$t("contact_information")' :label-width="formLabelWidth">
				{{form.contact_type}}:{{form.contact_number}}
			</el-form-item>
			<el-form-item prop="service_phone" :label='$t("service_phone")' :label-width="formLabelWidth">
				{{form.service_phone}}
			</el-form-item>
			<el-form-item prop="apply_info" :label='$t("application_description")' :label-width="formLabelWidth">
				{{form.apply_info}}
			</el-form-item>
			<el-form-item prop="apply_info" :label='$t("agency_fees")' :label-width="formLabelWidth">
				{{form.grade_str}}
			</el-form-item>
			<el-form-item prop="apply_info" :label='$t("application_date")' :label-width="formLabelWidth">
				{{form.created_at}}
			</el-form-item>
			<div class="u-p-l-150 u-m-t-15">
				<el-button type="primary" class="u-m-l-10"
					@click="handleClose">{{$t("confirm")}}</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>	
	import {
		watch,
		ref
	} from 'vue';
import i18n from '@/lang'
	export default {
		name: 'detail',
		components: {
		},
		props: {
		  dialogVisible: {
		    type: Boolean,
		  },
		  row: {
		    type: Object,
		  },
		},
		emits: ['reloadList','update:dialogVisible'],
		setup(props,{emit}) {
			watch(
			  () => props.dialogVisible,
			  (v) => {
			    //更换父组件dialogVisible的值
			    emit('update:dialogVisible', v)
			
			    if(v){
			      form.value = props.row
			    }
			  }
			)
			
			let form = ref({})
			
			let formLabelWidth = '150px';
			
			const handleClose = ()=>{
				emit('update:dialogVisible', false)
			}
						
			return {
				form,
				formLabelWidth,
				handleClose
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-box {
		align-items: flex-start;
	}

	.img-box {
		position: relative;
	}

	.del-icon {
		color: red;
		background: #fff;
		font-size: 26px;
		border-radius: 13px;
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
	}

	.el-link {
		font-size: 16px;
		vertical-align: top;
	}

	.tips-span {
		padding: 0 0 20px 45px;
		color: #999999
	}

	.editor-btn {
		color: #8c7065;
		display: flex;
		align-items: center;
		padding-left: 90%;

		span {
			margin-left: 5px;
			cursor: pointer;
		}
	}
</style>