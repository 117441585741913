<!--
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-06-22 15:06:14
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("withdrawal_management")}}</span>
        </div>
		<div class="u-flex u-m-l-auto u-row-center">
			<el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
		  <el-button type="text" @click="showSiftData" icon="Filter">{{$t("filter")}}</el-button>
		</div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
        @sort-change="sortOnChange"
      >
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="username" :label="$t('apply_for_an_account')" width="180" />
        <el-table-column prop="amount" :label="$t('amount')" />
		<el-table-column prop="account_type" :label="$t('withdrawal_method')" ></el-table-column>
		<el-table-column prop="account_name" :label="$t('account')" ></el-table-column>
		<el-table-column prop="account_number" :label="$t('name')" ></el-table-column>
		<el-table-column prop="bank_name" :label="$t('bank')" ></el-table-column>
        <el-table-column
          prop="created_at"
          :label="$t('submission_time')"
          width="180"
          sortable="custom"
        />
        <el-table-column prop="status_str" :label="$t('status')"></el-table-column>
		<el-table-column prop="refuse_reason" :label="$t('reject_reason')"></el-table-column>
        <el-table-column fixed="right" :label="$t('action')" width="300">
          <template #default="scope">
			<el-button
				v-if="scope.row.status == 0"
				type="text"
				size="small" @click="handlePass(scope.row.id)">{{$t("approved")}}</el-button>
			<el-button type="text"
				v-if="scope.row.status == 1"
              size="small" @click="handlePaid(scope.row.id)">{{$t("payment")}}</el-button>
			<el-button type="text"
			   v-if="scope.row.status == 0 || scope.row.status == 1"
              size="small" @click="handleRefuse(scope.row.id)">{{$t('refuse')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
	<sift
	  v-model:dialogVisible="dialogSiftVisible"
	  @reloadList="searchData"
	  :defaultParams="table.queryParams"
	  :withdrawType = "withdraw_type"
	  :withdrawStatus = "withdraw_status"
	></sift>
  </div>
  <detail v-model:dialogVisible="dialogDetailVisible"  :row="updateRow" ></detail>
</template>

<script>
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getWithdrawList,refuseWithdraw,paidWithdraw,passWithdraw } from '@/api/withdraw'
import notice from '@/utils/notice'
import { useStore } from 'vuex'
import { watch, ref, computed } from 'vue'
import {useRouter} from "vue-router";
import sift from './components/sift'
import { ElMessageBox } from 'element-plus'
import i18n from '@/lang'
export default {
  name: 'widthdraw',
  components: { sift},
  setup() {
    let dialogDetailVisible = ref(false)
    const store = useStore()
    const router = useRouter()
	let dialogSiftVisible = ref(false)
    const keys = {
      created_at: 'created_at_sort',
      last_login_time: 'last_login_time_sort',
    }
    const values = {
      ascending: 'asc',
      descending: 'desc',
    }
    const updateRow = ref({})
	
	let withdraw_type = ref({})
	let withdraw_status = ref({})
	const { t } = i18n.global
    //加载列表数据
    let table = tableDefaultData()
	
    const requestData = () => {
		table.loading = true
		getWithdrawList(table.getQueryParams()).then((response) => {
			table.loading = false

			tableDataFormat(response, table)
			withdraw_type.value = response.data.withdraw_type
			withdraw_status.value = response.data.withdraw_status
		})
    }
	const searchData = (params)=>{
		table.queryParams = params
		requestData()
	}
    requestData()
    const showUserDetail = (index, row) => {
      updateRow.value = row
      dialogDetailVisible.value = true
    }
    const sortOnChange = (column, prop, order) => {
      const key = keys[column.prop]
      const value = values[column.order]

      const sortparams = { [key]: value }
      table.queryParams = sortparams
      requestData()
    }

	const showSiftData = () => {
	  dialogSiftVisible.value = true
	}
	const handlePass = (id)=>{
		ElMessageBox.confirm(
		    t('confirm_approval'),
		    t('prompt'),
		    {
		      confirmButtonText: t("confirm"),
		      cancelButtonText: t("cancel"),
		      type: 'warning',
		    }
		  )
		    .then(() => {
				passWithdraw({id:id}).then((response)=>{
					notice.success(t("success"));
					requestData()
				})
		    })
		    .catch(() => {

		    })
	}
	
	const handlePaid = (id)=>{
		ElMessageBox.confirm(
		    t("confirm_paid"),
		    t('prompt'),
		    {
		      confirmButtonText: t("confirm"),
		      cancelButtonText: t("cancel"),
		      type: 'warning',
		    }
		  )
		    .then(() => {
				paidWithdraw({id:id}).then((response)=>{
					notice.success(t("success"));
					requestData()
				})
		    })
		    .catch(() => {
		
		    })
	}
	
	const handleRefuse = (id)=>{
		ElMessageBox.prompt(
		    t("please_fill_in_the_reason_for_rejection"),
		    t("refusal_to_withdraw"),
		    {
		      confirmButtonText: t("confirm"),
		      cancelButtonText: t("cancel"),
		      inputErrorMessage: t("please_fill_in_the_reason_for_rejection"),
		    }
		  )
		    .then(({ value }) => {
				refuseWithdraw({id:id,refuse_reason:value}).then((response)=>{
					notice.success(t("success"));
					requestData()
				})
		    })
		    .catch(() => {
		
		    })
	}
	const reflashData = () => {
	
	  Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
	  console.log(table.queryParams);
	  requestData()
	}
	
    watch(dialogDetailVisible, v => {
      if (!v) {
        updateRow.value = {}
      }
    })

    return {
	  dialogSiftVisible,
	  showSiftData,
		reflashData,
      dialogDetailVisible,
      table,
      requestData,
      showUserDetail,
      sortOnChange,
      updateRow,
	  searchData,
	  handlePass,
	  handlePaid,
	  handleRefuse,
	  withdraw_type,
	  withdraw_status
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>