import config from '@/config'

const state = {
  locale:config.locale,
  paymentTypes:config.paymentTypes,
  orderStatus:config.orderStatus,
}

const getters = {
  locale: state => state.locale,
  paymentTypes: state => state.paymentTypes,
  orderStatus: state => state.orderStatus
}


const mutations = {
  SET_LOCALE (state, locale) {
    state.locale = locale
  }
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}