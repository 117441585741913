<!--
 * @Descripttion: 这是描述
 * @Date: 2022-05-16 17:45:41
 * @LastEditTime: 2022-07-07 17:47:11
-->
<template>
  <div>
    <h1 style="text-align: center;">{{$t("welcome")}}</h1> 
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { useStore } from 'vuex'
import { ref, nextTick } from 'vue-demi'
import { dateFormat } from '@/utils/helper'

export default {
  name: 'adminDashboard',
  setup() {
    

    return {
      
    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  padding: 20px;
  width: 180px;
  height: 50px;
  background: #fff;
  margin-right: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--el-card-border-radius);
  .money {
    margin-top: 10px;
  }
  .ratio {
    margin-left: 10px;
    color: rgb(179, 58, 58);
    font-size: 12px;
  }
}
#main {
  width: 1200px;
  height: 500px;
}
::v-deep(.date-picker) {
  position: absolute;
  right: 20px;
  top: 0;
}
.top-ranking {
  list-style-type: none;
  line-height: 40px;
  padding: 0 10px 0 0;
  .rankItem {
    display: flex;
    align-items: center;
    &:first-child .top-ranking-index{
       background: rgb(2,135,251);
    }
    &:nth-child(2) .top-ranking-index{
       background: rgb(254,63,25);
    }
    &:nth-child(3) .top-ranking-index{
       background: rgb(249,182,22);
    }
    .top-ranking-index {
      display: inline-block;
      border-radius: 34px;
      width: 17px;
      height: 17px;
      text-align: center;
      line-height: 18px;
      background: rgb(192, 192, 192);
      color: white;
      font-size: 12px;
    }
    .top-ranking-name {
      width: 200px;
      margin: 0 10px;
      flex-basis: 200px;
    }
    .top-ranking-num {
      font-size: 20px;
      color: red;
      text-align: right;
      flex: 1;
    }
    li:nth-child(1) {
      .top-ranking-index {
        background: rgb(61, 106, 233);
      }
    }
    li:nth-child(2) {
      .top-ranking-index {
        background: rgb(254, 64, 27);
      }
    }
    li:nth-child(3) {
      .top-ranking-index {
        background: rgb(249, 182, 22);
      }
    }
  }
}
</style>