/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 09:39:27
 * @LastEditTime: 2022-06-23 14:32:30
 */
import routers from '@/router/routes';
import store from '@/store'
// import i18n from '@/lang'

export const hasPermission = (name) => {
    // return store.getters.permissions.indexOf(name) >= 0
    return true
}

export const routeByName = (name) => {
    let router;

    let each = (routers, name) => {
        for (let item of routers) {
            if (item.name === name) {
                router = item
            }

            if (router) {
                break
            }

            if (item.hasOwnProperty('children') && item.children.length > 0) {
                each(item.children, name)
            }
        }
        return router;
    }

    return each(routers, name)
}

export const routeFormatTag = route => {
    return {
        name: route.name,
        fullPath: route.fullPath,
        title: route.meta.name ? route.meta.name : '',
        cache: route.meta && route.meta.cache,
        closable: !route.meta.notClosable,
    }
}

export const getCascaderDefaultIds = (node) => {
    let ids = []
    let tempNode = node
    while (tempNode.data.parent_id) {
        ids.push(tempNode.data.parent_id)
        tempNode = tempNode.parent
    }

    return ids.reverse()
}


export const getNodeParentPath = (id, nodes, path = {}) => {
    for (let i = 0; i < nodes.length; i++) {
        if (path.status) {
            break
        }

        let node = nodes[i]
        if (node.parent_id === 0) {
            path.ids = []
        }

        if (i === 0 && node.parent_id > 0) {
            path[node.parent_id] = [...path.ids]
        }

        if (id === node.id) {
            path.status = true
            path.ids = path.hasOwnProperty(node.parent_id) ? [...path[node.parent_id]] : []
            break
        } else {
            path.ids.push(node.id)
        }

        if (node.children) {
            getNodeParentPath(id, node.children, path)
        }
    }
}

export const getTagTitleName = (titleKey) => {
    let metaKey = `meta.title.${titleKey}`

    // return i18n.global.te(metaKey, store.getters.locale) ? i18n.global.t(metaKey) : titleKey
}



export const timestampToTime = (timestamp) => {

    let date = new Date(timestamp);
    //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM; //月补0
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d; //天补0
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h; //小时补0
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m; //分钟补0
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s; //秒补0
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export const dateFormat=(fmt, date) =>{
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export const getMonthDay = (year, month) => {
    let days = new Date(year, month, 0).getDate()
    return days
}
export const checkImgType = (fileName) => { //判断是否是图片
    //用文件名name后缀判断文件类型，可用size属性判断文件大小不能超过500k ， 前端直接判断的好处，免去服务器的压力。 
    if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName)) {
        return false;
    } else {
        return true;
    }
};


// 
/**
 * base64转化为图片file文件
 * dataurl  base64字符串，要剔除base64...,前面的字符串
 * filename 要命名的文件名
 * type 文件命名 ex: image/png
 * */
export const dataURLtoFile = (dataurl, filename, type) => {
    const bstr = atob(dataurl)
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type
    }); // file 类型
}


export const isMobile= (value)=> {
    //是否为手机号
    return /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/.test(value);
}

//下载文件
export const downloadFile = async (res,name) => {
    /* let response = await fetch(res.data.url); // 内容转变成blob地址
    let blob = await response.blob();  // 创建隐藏的可下载链接
    let objectUrl = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = objectUrl;
    a.download = res.data.name;
    a.click()
    a.remove(); */
	var x = new XMLHttpRequest();
	    x.open("GET", res.data.url, true);
	    x.responseType = 'blob';
	    x.onload=function(e) {
	        var url = window.URL.createObjectURL(x.response)
	        var a = document.createElement('a');
	        a.href = url
	        a.download = name;
	        a.click()
	    }
	    x.send();
}