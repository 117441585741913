export default {
    "home":"Home",
	"change_password":"Change password",
	"login_out":"Login out",
	"welcome":"Welcome to the backend management system",
	"agent_audit_management":"Agent audit management",
	"refresh":"Refresh",
	"filter":"Filter",
	"agent_id":"Agent ID",
	"apply_for_an_account":"Apply for an account",
	"apply_for_agency_level":"Apply for agency level",
	"application_for_agency_area":"Application for agency area",
	"withdraw_time":"Withdraw time",
	"status":"Status",
	"action":"Action",
	"view":"View",
	"approved":"Approved",
	"confirm_payment":"Confirm payment",
	"refuse":"Refuse",
	"user_information":"User information",
	"application_type":"Application type",
	"time_period_selection":"Time period selection",
	"to":"To",
	'start_time':"Start time",
	"end_time":"End time",
	"cancel":"Cancel",
	"submit":"Submit",
	"detail":"Detail",
	"territory":"Territory",
	"company_name":"Company name",
	"company_size":"Company size",
	"office_address":"Office address",
	"contacts_name":"Contacts name",
	"contact_information":"Contact information",
	"service_phone":"Service phone",
	"application_description":"Application description",
	"agency_fees":"Agency fees",
	"application_date":"Application date",
	"confirm":"Confirm",
	"change_pwd":"Change password",
	"change_pwd_tip":"After successful modification, the current login will be automatically logged out！",
	"old_pwd":"Old password",
	"new_pwd":"Password",
	"confirm_password":"Confirm Password",
	"reset":"Reset",
	"please_input_your_old_password":"Please input your old password",
	"please_enter_a_new_password":"Please enter a new password",
	"the_password_must_be_between_6_and_32_characters":"The password must be between 6 and 32 characters",
	"please_enter_the_new_password_again":"Please enter the new password again",
	"entered_passwords_differ":"Entered passwords differ",
	"login":"Login",
	"please_enter_the_login_account":"Please enter the login account",
	"please_enter_the_password":"Please enter the password",
	"user_name_is_required":"User name is required",
	"password_is_required":"Password is required",
	"user_manage":"User manage",
	"account":"Account",
	"grade":"Grade",
	"franchise_amount":"Franchise amount",
	"edit":"Edit",
	"submission_time":"Submission time",
	"success":"Success",
	"original_grade":"Original grade",
	"payment_for_goods":"Payment for goods",
	"positive_numbers_increase_negative_numbers_subtract":"Positive numbers increase negative numbers subtract",
	"live_streaming_permissions":"Live streaming permissions",
	"disable":"Disable",
	"enable":"Enable",
	"withdrawal_management":"Withdrawal management",
	"amount":"Amount",
	"withdrawal_method":"Withdrawal method",
	"name":"Name",
	"bank":"Bank",
	"reject_reason":"Reject reason",
	"payment":"Payment",
	"confirm_approval":"Confirm approval?",
	"prompt":"Prompt",
	"confirm_paid":"Confirm paid?",
	"please_fill_in_the_reason_for_rejection":"Please fill in the reason for rejection",
	"refusal_to_withdraw":"Refusal to withdraw"
}