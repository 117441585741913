/*
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-05-16 15:56:32
 */
import withdraw from "@/views/withdraw"
export default [{
    name: 'withdraw',
    path: '/withdraw',
    meta: {
        id: '0',
        icon: 'Wallet',
        title: 'withdrawal_management',
        name: 'withdrawal_management',
        cache: true,
		permission:"withdraw-manage"
    },
    component: withdraw
}, ]