/*
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 09:23:01
 * @LastEditTime: 2022-05-16 15:56:32
 */
import userlistPage from "@/views/userlist"
export default [{
    name: 'userlist',
    path: '/userlist',
    meta: {
        id: '0',
        icon: 'User',
        title: 'user_manage',
        name: 'user_manage',
        cache: true,
		permission:'user-manage'
    },
    component: userlistPage
}, ]