<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 10:29:56
 * @LastEditTime: 2022-05-16 14:36:17
-->
<template>
  <el-container style="height:100%">
    <nav-bar :is-collapse="isCollapse"></nav-bar>
    <el-container direction="vertical">
      <mojito-header :is-collapse="isCollapse" @menu="changeMenuStatus"></mojito-header>
      <tags-view></tags-view>
      <el-main>
        <router-view v-slot="{ Component }">
          <el-scrollbar height="100%">
            <keep-alive :include="cacheTags">
              <component :is="Component" />
            </keep-alive>
          </el-scrollbar>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import MojitoHeader from "@/layout/components/Header.vue"
import NavBar from "@/layout/components/NavBar.vue"
import TagsView from "@/layout/components/TagsView.vue"
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

export default {
  components: { 
    MojitoHeader,
    NavBar,
    TagsView,
  },
  name: 'MainPage',
  setup() {
    const store = useStore()

    //store.dispatch('loadPermissions')

    let isCollapse = ref(false)

    const cacheTags = computed(() => {
      return store.getters.cacheTags
    })

    const changeMenuStatus = (status) => {
        isCollapse.value = status
    }

    return {
      isCollapse,
      cacheTags,
      changeMenuStatus,
    }
  },
}
</script>
<style lang="scss" scoped>
  .el-main {
    // padding:0;
    //background-color: #f0f2f5;
  }
  ::v-deep(.el-scrollbar__view){
    height: 100%;
  }
  .el-main .el-scrollbar__view {
    height: auto;
  }
</style>