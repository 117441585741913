<!--
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-06-22 15:06:14
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("agent_audit_management")}}</span>
        </div>
		<div class="u-flex u-m-l-auto u-row-center">
			<el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
		  <el-button type="text" @click="showSiftData" icon="Filter">{{$t("filter")}}</el-button>
		</div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
        @sort-change="sortOnChange"
      >
        <el-table-column prop="id" :label="$t('agent_id')" width="100" />
        <el-table-column prop="username" :label="$t('apply_for_an_account')" width="180" />
        <el-table-column prop="grade" :label="$t('apply_for_agency_level')" />
		<el-table-column :label="$t('application_for_agency_area')" >
			<template #default="scope">
				{{scope.row.province}}
				{{scope.row.city}}
				{{scope.row.district}}
			</template>
		</el-table-column>
        <el-table-column
          prop="created_at"
          :label="$t('withdraw_time')"
          width="180"
          sortable="custom"
        />
        <el-table-column prop="status_str" :label="$t('status')"></el-table-column>
        <el-table-column fixed="right" :label="$t('action')" width="300">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click="showUserDetail(scope.$index, scope.row)"
              >{{$t("view")}}</el-button
            >
			<el-button
				v-if="scope.row.status == 0"
				type="text"
				size="small" @click="handlePass">{{$t("approved")}}</el-button>
			<el-button type="text"
				v-if="scope.row.status == 1"
              size="small" @click="handlePaid">{{$t("confirm_payment")}}</el-button>
			<el-button type="text"
			   v-if="scope.row.status == 0 || scope.row.status == 1"
              size="small" @click="handleRefuse">{{$t("refuse")}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
	<agent-apply-sift
	  v-model:dialogVisible="dialogSiftVisible"
	  @reloadList="searchData"
	  :defaultParams="table.queryParams"
	  :userGrade = "user_grade"
	  :applyStatus = "apply_status"
	></agent-apply-sift>
  </div>
  <detail v-model:dialogVisible="dialogDetailVisible"  :row="updateRow" ></detail>
</template>

<script>
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getAgentApplyList,passAgentApply,paidAgentApply,refuseAgentApply } from '@/api/agentApply'
import notice from '@/utils/notice'
import { useStore } from 'vuex'
import { watch, ref, computed } from 'vue'
import {useRouter} from "vue-router";
import AgentApplySift from './components/agentApplySift'
import Detail from './components/detail'
import { ElMessageBox } from 'element-plus'
export default {
  name: 'agentApply',
  components: { AgentApplySift,Detail},
  setup() {
    let dialogDetailVisible = ref(false)
    const store = useStore()
    const router = useRouter()
	let dialogSiftVisible = ref(false)
    const keys = {
      created_at: 'created_at_sort',
      last_login_time: 'last_login_time_sort',
    }
    const values = {
      ascending: 'asc',
      descending: 'desc',
    }
    const updateRow = ref({})
	
	let user_grade = ref({})
	let apply_status = ref({})
    //加载列表数据
    let table = tableDefaultData()
	
    const requestData = () => {
		table.loading = true
		getAgentApplyList(table.getQueryParams()).then((response) => {
			table.loading = false

			tableDataFormat(response, table)
			user_grade.value = response.data.user_grade
			apply_status.value = response.data.apply_status
		})
    }
	const searchData = (params)=>{
		table.queryParams = params
		requestData()
	}
    requestData()
    const showUserDetail = (index, row) => {
      updateRow.value = row
      dialogDetailVisible.value = true
    }
    const sortOnChange = (column, prop, order) => {
      const key = keys[column.prop]
      const value = values[column.order]

      const sortparams = { [key]: value }
      table.queryParams = sortparams
      requestData()
    }

	const showSiftData = () => {
	  dialogSiftVisible.value = true
	}
	const handlePass = ()=>{
		ElMessageBox.confirm(
		    '确定审核通过?',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  )
		    .then(() => {
				passAgentApply().then((response)=>{
					notice.success("操作成功");
					requestData()
				})
		    })
		    .catch(() => {

		    })
	}
	
	const handlePaid = ()=>{
		ElMessageBox.confirm(
		    '确定已支付?',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  )
		    .then(() => {
				paidAgentApply().then((response)=>{
					notice.success("操作成功");
					requestData()
				})
		    })
		    .catch(() => {
		
		    })
	}
	
	const handleRefuse = ()=>{
		ElMessageBox.confirm(
		    '确定拒绝?',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  )
		    .then(() => {
				refuseAgentApply().then((response)=>{
					notice.success("操作成功");
					requestData()
				})
		    })
		    .catch(() => {
		
		    })
	}
	const reflashData = () => {
	
	  Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
	  console.log(table.queryParams);
	  requestData()
	}
	
    watch(dialogDetailVisible, v => {
      if (!v) {
        updateRow.value = {}
      }
    })

    return {
	  dialogSiftVisible,
	  showSiftData,
		reflashData,
      dialogDetailVisible,
      table,
      requestData,
      showUserDetail,
      sortOnChange,
      updateRow,
	  searchData,
	  handlePass,
	  handlePaid,
	  handleRefuse,
	  user_grade,
	  apply_status
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>