import http from '@/utils/http'

export const loadPermissions = () => {
  return http.get('permission-user-all')
}

const basicRoute = 'system-permission'

export const getPermissionList = (params) => {
  return http.get(basicRoute, {
    params
  })
}