<!--
 * @Descripttion: 用户管理
 * @Date: 2022-03-19 15:28:23
 * @LastEditTime: 2022-06-22 15:06:14
-->
<template>
  <div>
    <el-card>
      <div class="u-flex u-100 u-m-b-20">
        <div>
          <span class="page-title">{{$t("user_manage")}}</span>
        </div>
		<div class="u-flex u-m-l-auto u-row-center">
			<el-button type="text" @click="reflashData" >{{$t("refresh")}}</el-button>
		  <el-button type="text" @click="showSiftData" icon="Filter">{{$t('filter')}}</el-button>
		</div>
      </div>
      <el-table
        :data="table.data"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="username" :label="$t('account')"  />
        <el-table-column prop="bonus_grade" v-if="is_bonus" :label="$t('grade')" />
		<el-table-column prop="pay_balance" v-if="is_bonus" :label="$t('franchise_amount')" />
        <el-table-column
          prop="created_at"
          :label="$t('submission_time')"
        />
        <el-table-column fixed="right" :label="$t('action')" >
          <template #default="scope">
			<el-button type="text"
              size="small" @click="handleEdit(scope.index,scope.row)">{{$t("edit")}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination-right"
        @current-change="requestData"
        v-model:currentPage="table.pagination.currentPage"
        :page-size="table.pagination.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="table.pagination.total"
      >
      </el-pagination>
    </el-card>
	<userlist-sift
	  v-model:dialogVisible="dialogSiftVisible"
	  @reloadList="searchData"
	  :defaultParams="table.queryParams"
	  :userGrade = "user_grade"
	></userlist-sift>
  </div>
  <detail v-model:dialogVisible="dialogDetailVisible" @updateRow="setLevel" :row="updateRow" :grade="user_grade" :bonus_amount="bonus_amount"></detail>
</template>

<script>
import { tableDefaultData, tableDataFormat } from '@/utils/table'
import { getUserlist,setUserLevel } from '@/api/userlist'
import notice from '@/utils/notice'
import { useStore } from 'vuex'
import { watch, ref, computed } from 'vue'
import {useRouter} from "vue-router";
import userlistSift from './components/userlistSift'
import Detail from './components/detail'
import { ElMessageBox } from 'element-plus'
import i18n from '@/lang'
export default {
  name: 'userlist',
  components: { userlistSift,Detail},
  setup() {
    let dialogDetailVisible = ref(false)
    const store = useStore()
    const router = useRouter()
	let dialogSiftVisible = ref(false)
    const values = {
      ascending: 'asc',
      descending: 'desc',
    }
    const updateRow = ref({})
	
	let user_grade = ref({})
	
	let bonus_amount = ref({})
	
	let is_bonus = ref(false)
	const { t } = i18n.global
	
	is_bonus = computed(()=>store.getters.distribution_type === 'bonus');
    //加载列表数据
    let table = tableDefaultData()
	
    const requestData = () => {
		table.loading = true
		getUserlist(table.getQueryParams()).then((response) => {
			table.loading = false

			tableDataFormat(response, table)
			user_grade.value = response.data.user_grade
			bonus_amount.value = response.data.bonus_amount
		})
    }
	const searchData = (params)=>{
		table.queryParams = params
		requestData()
	}
    requestData()
    const handleEdit = (index, row) => {
      updateRow.value = row
      dialogDetailVisible.value = true
    }

	const showSiftData = () => {
	  dialogSiftVisible.value = true
	}
	
	const reflashData = () => {
	
	  Object.keys(table.queryParams).forEach(key => (table.queryParams[key] = ''));
	  console.log(table.queryParams);
	  requestData()
	}
	
	const setLevel = (params) => {
		setUserLevel(params).then((response) => {
			notice.success(t("success"));
			dialogDetailVisible.value = false
			reflashData()
		})
	}
	
	
    watch(dialogDetailVisible, v => {
      if (!v) {
        updateRow.value = {}
      }
    })

    return {
	  dialogSiftVisible,
	  showSiftData,
		reflashData,
      dialogDetailVisible,
      table,
      requestData,
      handleEdit,
      updateRow,
	  searchData,
	  user_grade,
	  setLevel,
	  bonus_amount,
	  is_bonus
    }
  },
}
</script>

<style lang="scss" scoped>
.situationboard {
  display: flex;
  position: relative;
  height: 140px;
  min-width: 720px;
  width: calc(100% - 208px);
  .situation {
    text-align: left;
    line-height: 28px;
    min-width: 130px;
    padding: 24px 16px;
    .name {
      font-size: 14px;
    }
    .prev,
    .prevnum {
      color: #969799;
      font-size: 10px;
    }
  }
}
</style>