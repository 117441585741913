const state = {
  local: '',
  breadcrumb: []
}

const getters = {
  breadcrumb: state => state.breadcrumb
}

const mutations = {
  SET_BREADCRUMB (state, breadcrumb) {
    const res= breadcrumb.filter( item => {
     return item.meta.permission
    })
    // console.log('res',res);
    state.breadcrumb = res
  }
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}