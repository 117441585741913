/*
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 12:04:44
 * @LastEditTime: 2022-03-21 10:00:05
 */
import { createStore } from "vuex"
import app from './modules/app'
import breadcrumb from "./modules/breadcrumb"
import auth from "./modules/auth"
import tag from "./modules/tag"
import permission from "./modules/permission"
import plugin from "./plugin"

const store = createStore({
  state: {
    env: typeof(window._env) == 'undefined' ? {} : window._env
  },
  modules: {
    app,
    auth,
	tag,
	permission,
    breadcrumb
  },
  plugins: [plugin]
})


export default store