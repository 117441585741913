<template>
	<el-dialog :modelValue="dialogVisible" width="620px" :title="$t('detail')">
		<el-form >
			<el-form-item :label='$t("account")' :label-width="formLabelWidth" prop="username">
				{{form.username}}
			</el-form-item>
			<el-form-item v-if="is_bonus" prop="bonus_grade" :label='$t("original_grade")' :label-width="formLabelWidth">
				{{form.bonus_grade}}
			</el-form-item>
			<el-form-item v-if="is_bonus" prop="bonus_grade" :label='$t("payment_for_goods")' :label-width="formLabelWidth">
				{{form.pay_balance}}
			</el-form-item>
			<el-form-item v-if="is_bonus" prop="bonus_grade" :label='$t("grade")' :label-width="formLabelWidth">
				<el-select v-model="data.level" @change="changeAmount">
					<el-option v-for="(index,item) in grade" :label="index" :value="item" :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="is_bonus" prop="pay_balance" :label='$t("franchise_amount")' :label-width="formLabelWidth">
				<span><el-input v-model="data.pay_balance"></el-input></span>
				<span class="tips-span">{{$t("positive_numbers_increase_negative_numbers_subtract")}}</span>
			</el-form-item>
			<el-form-item v-if="!is_bonus" prop="live_permission" label='$t("live_streaming_permissions")' :label-width="formLabelWidth">
				<el-radio-group v-model="data.live_permission">
					<el-radio :label="0">{{$t('disable')}}</el-radio>
					<el-radio :label="1">{{$t('enable')}}</el-radio>
				</el-radio-group>
			</el-form-item>
			<div class="u-p-l-150 u-m-t-15">
				<el-button type="primary" class="u-m-l-10"
					@click="handleClose">{{$t('confirm')}}</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>	
	import {
		watch,
		ref,
		computed
	} from 'vue';
import { useStore } from 'vuex'
import i18n from '@/lang'
	export default {
		name: 'detail',
		components: {
		},
		props: {
		  dialogVisible: {
		    type: Boolean,
		  },
		  row: {
		    type: Object,
		  },
		  grade:{
			type:Object
		  },
		  bonus_amount:{
			type:Object
		  }
		},
		emits: ['reloadList','update:dialogVisible'],
		setup(props,{emit}) {
			const store = useStore()
			watch(
			  () => props.dialogVisible,
			  (v) => {
			    //更换父组件dialogVisible的值
			    emit('update:dialogVisible', v)
			
			    if(v){
					console.log(props.row)
			      form.value = props.row
				  data.value.user_id = form.value.id
				  data.value.level = ""
				  data.value.pay_balance = ""
				  data.value.live_permission = form.value.live_permission
			    }
			  }
			)
			
			let form = ref({})
			
			let data = ref({
				user_id:"",
				level:"",
				pay_balance:"",
				live_permission:0
			})
			
			let formLabelWidth = '150px';
			
			let is_bonus = ref(false)
			is_bonus = computed(()=>store.getters.distribution_type === 'bonus');
			
			const handleClose = ()=>{
				emit('updateRow', data.value)
			}
			
			const changeAmount = (value)=>{
				console.log(props.bonus_amount[value - 1])
				if(value > 0 && props.bonus_amount[value - 1])
				{
					data.value.pay_balance = props.bonus_amount[value - 1]
				}
				else{
					data.value.pay_balance = 0
				}
			}

			return {
				form,
				formLabelWidth,
				handleClose,
				changeAmount,
				data,
				is_bonus
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-box {
		align-items: flex-start;
	}

	.img-box {
		position: relative;
	}

	.del-icon {
		color: red;
		background: #fff;
		font-size: 26px;
		border-radius: 13px;
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
	}

	.el-link {
		font-size: 16px;
		vertical-align: top;
	}

	.tips-span {
		padding: 0 0 20px 45px;
		color: #999999
	}

	.editor-btn {
		color: #8c7065;
		display: flex;
		align-items: center;
		padding-left: 90%;

		span {
			margin-left: 5px;
			cursor: pointer;
		}
	}
	.tips-span{
		padding-bottom: 0;
	}
</style>