<!--
 * @Descripttion: 这是描述
 * @Date: 2022-03-19 10:11:51
 * @LastEditTime: 2022-03-19 10:37:57
-->
<template>
  <div></div>
  <!-- <el-result icon="warning" title="404" :subTitle="$t('notFoundErrorMessage')">
    <template #extra>
      <el-button type="primary" size="small" @click="back">{{ $t("back") }}</el-button>
    </template>
  </el-result> -->
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: "PageNotFound",
  setup() {
    const router = useRouter()

    const back = () => {
      router.back()
    }

    return {
      back,
    }
  }
}
</script>
<style lang="scss" scope>
  
</style>