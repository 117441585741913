<template>
  <el-dialog :modelValue="dialogVisible" width="620px" :title="$t('filter')">
    <el-form ref="formRef" :model="form" label-width="120px">
		<el-form-item :label="$t('user_information')">
			<span><el-input v-model="form.keyword"></el-input></span>
		</el-form-item>
		<el-form-item v-if="is_bonus" :label="$t('grade')">
			<el-select v-model="form.bonus_grade">
				<el-option v-for="(option,index) in userGrade" :label="option" :value="index" :key="index"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label-width='50px'>
			<div class="btn-box">
				<div>
					<el-button @click="handleRest">{{$t("reset")}}</el-button>
					<el-button type="primary" @click="handleSift">{{$t("submit")}}</el-button>
				</div>	
			</div>
		</el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { watch, reactive, ref, onMounted, onBeforeUpdate, computed } from 'vue'

import { useStore } from 'vuex'

export default {
  components: {
  },
  props: {
    dialogVisible: {
      type: Boolean,
    },
    defaultParams: {
      type: Object,
    },
	userGrade:{
		type:Object
	}
  },
  emits: ['reloadList','update:dialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    let formLabelWidth = '100px'
    const formRef = ref(null)
    let form = reactive({
	  bonus_grade:'',
	  keyword:''
    })
	
	let dialogGoodsVisible = ref(false)
	
	let is_bonus = ref(false)
	is_bonus = computed(()=>store.getters.distribution_type === 'bonus');

    watch(
      () => props.dialogVisible,
      (v) => {
        //更换父组件dialogVisible的值
        emit('update:dialogVisible', v)

        if(v){
          console.log('props.defaultParams',props.defaultParams)
          for(let i in props.defaultParams){
            if(form.hasOwnProperty(i)){
              form[i]= props.defaultParams[i];
            }
          }
        }
      }
    )
    const handleSift = () => {
      const siftForm = Object.assign({}, form)
      emit('reloadList', siftForm)
      emit('update:dialogVisible', false)
    }
    const handleRest = () => {
      formRef.value.resetFields()
	  emit('update:dialogVisible', false)
    }

    return {
      formLabelWidth,
      form,
      formRef,
      handleRest,
      handleSift,
	  is_bonus
    }
  },
}
</script>

<style lang="scss" scoped>
.mr10{
	margin: 0 6px; 
}	
.btn-box{
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.search_count{
	float: left;
	display: flex;
}
.select-goods-count{
	color: #79bbff;
}
</style>